




import { Vue, Component, Prop, Watch } from "vue-property-decorator";

import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";
import drilldown from "highcharts/modules/drilldown";

drilldown(Highcharts);

@Component({
  components: {
    highcharts: Chart
  }
})
export default class SCMList extends Vue {
  @Watch("series")
  updateSeries(newValue: any) {
    this.chartOptions.series = newValue;
  }
  @Watch("drilldown")
  updateDrillDown(newValue: any) {
    this.chartOptions.drilldown = newValue;
  }

  @Prop({
    default() {
      return {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: "pie"
      };
    }
  })
  chart!: any;

  @Prop({
    default() {
      return {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            format: "<b>{point.name}</b>: {point.y}"
          }
        },
        series: {
          dataLabels: {
            enabled: true,
            format: "{<b>point.name}</b>: {point.y}"
          }
        }
      };
    }
  })
  plotOptions!: any;

  @Prop({
    default() {
      return {
        text: "Chart Title"
      };
    }
  })
  title!: any;

  @Prop({
    default() {
      return {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b><br/>'
      };
    }
  })
  tooltip!: any;

  @Prop({
    default() {
      return [];
    }
  })
  series!: any;

  @Prop({
    default() {
      return {};
    }
  })
  drilldown!: any;

  @Prop({
    default() {
      return {};
    }
  })
  accessibility!: any;

  credits = { enabled: false };

  chartOptions = {
    chart: this.chart,
    plotOptions: this.plotOptions,
    title: this.title,
    tooltip: this.tooltip,
    series: this.series,
    accessibility: this.accessibility,
    drilldown: this.drilldown,
    credits: this.credits
  };
}
