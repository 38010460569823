






































































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { UserStore } from "@/store/modules";
import { User } from "@/store/models/user";
import { eventHub } from "@/utils/eventHub";

@Component
export default class AdminUserList extends Vue {
  pageNum: number = 1;
  selectedIndex = -1;
  data: User = { role: "SQCC Admin" } as User;
  viewVisible: boolean = false;
  saving: boolean = false;

  indexMethod(index: number) {
    let i = (this.adminList.page - 1) * this.adminList.page_size + index + 1;
    return i;
  }

  @Prop({ default: { demandCompany: false } })
  formVisible!: any;

  get adminList() {
    return UserStore.AdminUsers;
  }

  handlePrevNextClick(val: any, list: string) {
    this.$emit("handlePageChange", val, list);
  }

  onDeleteClick(index: any, id: number) {
    let users = UserStore.AdminUsers.results.filter(x => x.id == id);
    if (users.length > 0) {
      let user = users[0];
      this.$confirm(
        "This will permanently delete the admin user " +
          user.name +
          " and all related data. Do you want to continue?",
        "Warning",
        {
          confirmButtonText: "Delete",
          confirmButtonClass: "el-button--danger",
          cancelButtonText: "Cancel",
          showClose: false,
          type: "warning"
        }
      )
        .then(async () => {
          await UserStore.deleteCompanyUserById(id);
          this.$notify.success("User deleted");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Delete cancelled"
          });
        });
    }
  }

  editForm(index: number) {
    this.$validator.reset();
    this.selectedIndex = index;
    this.data = Object.assign({}, this.adminList.results[index]);
    this.viewVisible = true;

    if (this.data.oauth_provider == "facebook") {
      try {
        let anchor = document.createElement("a");
        anchor.href = this.data.picture;
        let queryStrings = anchor.search.substring(1);
        let params = queryStrings.split("&");
        let oauthId = params[0].split("=")[1];
        if (oauthId) {
          this.data.picture =
            "https://graph.facebook.com/" + oauthId + "/picture?type=large&height=500&width=500";
        }
      } catch {}
    } else if (this.data.oauth_provider == "google") {
      try {
        let params = this.data.picture.split("=s");
        let picture_url = params[0] + "=s500-c";
        if (picture_url) {
          this.data.picture = picture_url;
        }
      } catch {}
    }
  }

  clearData() {
    this.data = { role: "SQCC Admin" } as User;
  }

  created() {
    eventHub.$on("clearUserData", this.clearData);
  }

  cancel() {
    this.clearData();
    this.formVisible.admin = false;
  }

  async submit() {
    let isValid = await this.$validator.validateAll();
    if (isValid) {
      this.data.email = this.data.username;
      this.data.address = "";
      let error: boolean = false;
      this.saving = true;
      await UserStore.saveAdminUser(this.data).catch(reason => {
        error = true;
      });
      this.saving = false;
      if (error) {
        this.$notify.error("Saving User Failed");
      } else {
        this.$notify.success("Saved Successfully");
        this.clearData();
        this.formVisible.admin = false;
      }
    }
  }
}
