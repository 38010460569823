


















































































































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { UserStore, DemandSupplyStore } from "@/store/modules";
import { CompanyUser } from "@/store/models/user";
import { eventHub } from "@/utils/eventHub";

@Component
export default class DemandCompanyList extends Vue {
  pageNum: number = 1;
  dialogVisible: boolean = false;
  rejectDialogVisible: boolean = false;
  dialogTitle = "Add Demand Company";
  rejectMessage: string = "";
  user: CompanyUser = {} as CompanyUser;
  tab: string = "demandCompany";
  approvedStatus: string = "";
  SendMessage: boolean = false;

  @Prop({ default: { demandCompany: false } })
  formVisible!: any;

  indexMethod(index: number) {
    let i = (this.demandCompanyList.page - 1) * this.demandCompanyList.page_size + index + 1;
    return i;
  }

  get demandCompanyList() {
    return UserStore.ConsumerCompany;
  }

  handlePrevNextClick(val: any, list: string) {
    this.$emit("handlePageChange", val, list, this.approvedStatus);
  }

  filterSeedCompany(filterValue?: any) {
    this.approvedStatus = filterValue;
    eventHub.$emit("changeDemandCompanyTabName", this.approvedStatus);
    UserStore.getConsumerCompanyUserList({
      approved: this.approvedStatus
    });
  }

  addForm() {
    this.$router.push({
      name: "Seedcomregister"
    }).catch(error => {});
  }

  async viewForm(index: number, id: number) {
    this.user = await UserStore.getCompanyByUserId(id);
    this.dialogVisible = true;
  }

  editForm(index: number, id: number) {
    this.$router.push({
      name: "Seedcomregister",
      query: { companyId: String(id), activeTab: "demandCompany" }
    }).catch(error => {});
  }

  async approve() {
    if (this.user.id != undefined && this.user.id != 0) {
      await UserStore.approveCompanyById({
        id: this.user.id,
        status: true
      });
      this.$notify.success("Organization is being approved. This may take some time.");
    }
  }

  async reject() {
    this.rejectDialogVisible = true;
  }

  async sendRejectMessage() {
    this.SendMessage = true;
    if (this.user.id != undefined && this.user.id != 0) {
      let params = {
        id: this.user.id,
        company_user: this.user.email,
        email_message: this.rejectMessage,
        status: false
      };
      await UserStore.rejectCompanyById(params);
      this.SendMessage = false;
      this.rejectDialogVisible = false;
      this.dialogVisible = false;
      this.$notify.success("Mail sent successfully");
    }
  }

  onDeleteClick(index: any, id: number) {
    let users = UserStore.ConsumerCompany.results.filter(x => x.id == id);
    if (users.length > 0) {
      let user = users[0];
      this.$confirm(
        "This will permanently delete the company " +
          user.name +
          " and all related data. Do you want to continue?",
        "Warning",
        {
          confirmButtonText: "Delete",
          confirmButtonClass: "el-button--danger",
          cancelButtonText: "Cancel",
          showClose: false,
          type: "warning"
        }
      )
        .then(async () => {
          await UserStore.deleteCompanyUserById(id);
          this.$notify.success("User deleted");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Delete cancelled"
          });
        });
    }
  }
}
