




import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";
import drilldown from "highcharts/modules/drilldown";

drilldown(Highcharts);

@Component({
  components: {
    highcharts: Chart
  }
})
export default class SCMList extends Vue {
  @Watch("series")
  updateSeries(newValue: any) {
    this.chartOptions.series = newValue;
  }

  @Watch("xAxis")
  updateXAxis(newValue: any) {
    this.chartOptions.xAxis = newValue;
  }

  @Watch("title")
  updateTitle(newValue: any) {
    this.chartOptions.title = newValue;
  }

  @Prop({
    default() {
      return {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: "spline"
      };
    }
  })
  chart!: any;

  @Prop({
    default() {
      return {
        series: {
          label: {
            connectorAllowed: false
          }
        }
      };
    }
  })
  plotOptions!: any;

  @Prop({
    default() {
      return {
        text: "Chart Title"
      };
    }
  })
  title!: any;

  @Prop({
    default() {
      return {
        pointFormat: "{series.name}: <b>{point.y}</b>"
      };
    }
  })
  tooltip!: any;

  @Prop({
    default() {
      return [];
    }
  })
  series!: any;

  @Prop({
    default: {}
  })
  xAxis!: any;

  @Prop({
    default() {
      return {
        title: {
          text: "Values"
        }
      };
    }
  })
  yAxis!: any;

  credits = { enabled: false };

  chartOptions = {
    chart: this.chart,
    plotOptions: this.plotOptions,
    title: this.title,
    tooltip: this.tooltip,
    yAxis: this.yAxis,
    xAxis: this.xAxis,
    legend: {
      layout: "vertical",
      align: "right",
      verticalAlign: "middle"
    },
    series: this.series,
    credits: this.credits
  };
}
