






































































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { UserStore } from "@/store/modules";
import { User } from "@/store/models/user";
import { eventHub } from "@/utils/eventHub";

@Component
export default class GeneralUserList extends Vue {
  pageNum: number = 1;
  selectedIndex = -1;
  data: User = {} as User;
  viewVisible: boolean = false;

  @Prop({ default: { demandCompany: false } })
  formVisible!: any;

  indexMethod(index: number) {
    let i = (this.generalUserList.page - 1) * this.generalUserList.page_size + index + 1;
    return i;
  }

  get generalUserList() {
    return UserStore.GeneralUsers;
  }

  handlePrevNextClick(val: any, list: string) {
    this.$emit("handlePageChange", val, list);
  }

  editForm(index: number) {
    this.$validator.reset();
    // this.formVisible.general = true;
    this.viewVisible = true;
    this.selectedIndex = index;
    this.data = Object.assign({}, this.generalUserList.results[index]);

    if (this.data.oauth_provider == "facebook") {
      try {
        let anchor = document.createElement("a");
        anchor.href = this.data.picture;
        let queryStrings = anchor.search.substring(1);
        let params = queryStrings.split("&");
        let oauthId = params[0].split("=")[1];
        if (oauthId) {
          this.data.picture =
            "https://graph.facebook.com/" + oauthId + "/picture?type=large&height=500&width=500";
        }
      } catch {}
    } else if (this.data.oauth_provider == "google") {
      try {
        let params = this.data.picture.split("=s");
        let picture_url = params[0] + "=s500-c";
        if (picture_url) {
          this.data.picture = picture_url;
        }
      } catch {}
    }
  }

  onDeleteClick(index: any, id: number) {
    let users = UserStore.GeneralUsers.results.filter(x => x.id == id);
    if (users.length > 0) {
      let user = users[0];
      this.$confirm(
        "This will permanently delete the general user " +
          user.name +
          " and all related data. Do you want to continue?",
        "Warning",
        {
          confirmButtonText: "Delete",
          confirmButtonClass: "el-button--danger",
          cancelButtonText: "Cancel",
          showClose: false,
          type: "warning"
        }
      )
        .then(async () => {
          await UserStore.deleteCustomUserById(id);
          this.$notify.success("User deleted");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Delete cancelled"
          });
        });
    }
  }

  clearData() {
    this.data = {} as User;
  }

  created() {
    eventHub.$on("clearUserData", this.clearData);
  }
}
